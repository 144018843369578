<template>
	<div id="renew">
		<div class="crumbs">
			<a href="###">首页</a>
			<label>></label>
			<a href="###">会员续费</a>
		</div>
		<ul>
			<li>
				<label>开通会员时长</label>
				<span>三个月</span>
			</li>
			<li>
				<label>缴费方式</label>
				<el-select v-model="value" placeholder="请选择缴费方式">
					<el-option
						v-for="item in options"
						:key="item.value"
						:label="item.label"
						:value="item.value">
					</el-option>
				</el-select>
			</li>
			<li>
				<label>缴费凭证</label>
				<el-upload
					class="upload"
					action=""
					:show-file-list="false"
					:on-success="handleSuccess"
				>
					<button>选择</button>
				</el-upload>
			</li>
			<li>
				<label></label>
				<a href="###">上一步</a>
				<a>提交</a>
				<a href="###">返回</a>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'member-renew',
	data() {
		return {
			value: '',
			options: [],
		};
	},
	methods: {
		handleSuccess(res, file) {
			this.$message.success('上传成功!');
		},
	}
};
</script>

<style lang="scss" scoped>
#renew{
	display: flex; flex-direction: column;
	& > ul{
		width: 940px; height: auto; border: 1px solid #ededed; padding: 64px;
		& > li{
			height: 36px; display: flex; align-items: center; margin: 0 0 15px; font-size: 20px; color: #333;
			label{ width: 150px; line-height: 1;}
			::v-deep.el-input__inner{ height: 34px; line-height: 34px;}
			::v-deep.upload{
				display: flex;
				button{ width: 64px; height: 34px; font-size: 16px; color: #fff; background: #3883FF; border: none; outline: none; cursor: pointer;}
			}
			& > a{
				width: 150px; height: 36px; background: #3883FF; text-align: center; line-height: 34px; border: 1px solid #3883FF; font-size: 18px; color: #fff; margin: 0 0 0 24px; text-decoration: none;
				&:nth-of-type(1){ margin: 0;}
				&:nth-of-type(3){ border: 1px solid #dadada; background: #fff; color: #333;}
			}
			&:last-child{ margin: 50px 0 0;}
		}
	}
}
</style>

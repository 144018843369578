<template>
	<div id="member">
		<div class="crumbs">
			<a href="###">首页</a>
			<label>></label>
			<a href="###">会员续费</a>
		</div>
		<div class="member-info">
			<span>
				<label>最后一次续费时长：</label>
				<i>3个月</i>
			</span>
			<span>
				<label>最后一次续费时间：</label>
				<i>2021-12-31 23:59:59</i>
			</span>
		</div>
		<ul>
			<li class="active">
				<p>
					<label>¥1200</label>
					<i>6个月</i>
				</p>
				<p>仅 ¥6.66/天</p>
			</li>
			<li>
				<p>
					<label>¥2000</label>
					<i>12个月</i>
				</p>
				<p>立即节省400元</p>
			</li>
			<li>
				<p>
					<label>¥5000</label>
					<i>36个月</i>
				</p>
				<p>立即节省1000元</p>
			</li>
		</ul>
		<div class="member-play">
			<span>
				<el-checkbox v-model="checked1" disabled>使用优惠券(0)</el-checkbox>
				<el-checkbox v-model="checked2">同意并接受《会员服务条款》</el-checkbox>
			</span>
			<span>
				<p>
					<i>实际支付</i>
					<label>¥1200</label>
					<i>(省400元)</i>
				</p>
				<p>
					<a href="###">立即续费</a>
					<a href="###">查看历史消费</a>
				</p>
			</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'member-index',
	data() {
		return {
			checked1: false,
			checked2: false,
		};
	},
	method:{
		
	}
};
</script>

<style lang="scss" scoped>
#member{
	display: flex; flex-direction: column; margin: 0 0 114px;
	.member-info{
		height: 80px; border: 1px solid #ededed; font-size: 20px; color: #333; line-height: 78px;
		span{
			i{ color: #3883FF; font-style: normal;}
			&:first-child{ margin: 0 70px 0 24px;}
		}
	}
	ul{
		width: 100%; height: 226px; display: flex; justify-content: space-between; margin: 38px 0 0;
		li{
			width: 290px; height: 100%; border: 2px solid #ededed; display: flex; flex-direction: column; align-items: center;
			&.active{ border-color: #3883FF;}
			p{
				&:first-child{
					line-height: 1; margin: 60px 0 30px;
					label{ font-size: 45px; color: #3883FF;}
					i{
						font-size: 20px; color: #999; font-style: normal; position: relative; padding: 0 0 0 20px;
						&::before{ width: 1px; height: 16px; background: #e1e1e1; content: ''; position: absolute; left: 10px; top: 6px;}
					}
				}
				&:last-child{ width: 226px; border-top: 1px solid #e1e1e1; line-height: 70px; font-size: 20px; color: #999; text-align: center;}
			}
		}
	}
	.member-play{
		border-top: 1px solid #ededed; margin: 48px 0 0; display: flex; flex-direction: column; align-items: flex-end;
		::v-deep.el-checkbox{
			margin: 0;
			.el-checkbox__label{ font-size: 16px;}
		}
		& > span{
			&:first-child{
				width: 326px; border-bottom: 2px solid #ededed; margin: 48px 0 0;
				& > label:last-child{ margin: 16px 0 48px;}
			}
			&:last-child{
				width: 326px;
				p{
					&:first-child{
						line-height: 1; margin: 60px 0 40px;
						i{ font-size: 16px; color: #333; font-style: normal;}
						label{ font-size: 45px; color: #3883FF; padding: 0 10px;}
					}
					&:last-child{
						display: flex; justify-content: space-between;
						a{
							width: 150px; height: 36px; font-size: 18px; border-width: 1px; border-style: solid; text-align: center; line-height: 34px; text-decoration: none;
							&:first-child{ border-color: #3883FF; background: #3883FF; color: #fff;}
							&:last-child{ border-color: #dadada; color: #333;}
						}
					}
				}
			}
		}
	}
}
</style>

<template>
  <div id="history">
    <div class="crumbs">
      <a href="###">首页</a>
      <label>></label>
      <a href="###">会员续费</a>
    </div>
    <div class="history-info">
      <span>
        <label>最后一次续费时长：</label>
        <i>3个月</i>
      </span>
      <span>
        <label>最后一次续费时间：</label>
        <i>2021-12-31 23:59:59</i>
      </span>
    </div>
    <el-table :data="tableData" v-loading="loading" highlight-current-row>
      <el-table-column prop="a" label="缴费时长"></el-table-column>
      <el-table-column prop="b" label="缴费方式"></el-table-column>
      <el-table-column prop="c" label="缴费时间"></el-table-column>
      <el-table-column prop="d" label="缴费结果"></el-table-column>
      <el-table-column prop="e" label="续费后会员到期日"></el-table-column>
    </el-table>
    <div class="page">
      <a @click="$router.back()">返回</a>
      <el-pagination
        background
        layout="total,prev, pager, next"
        :page-size="page.pageSize"
        :total="tableData.length"
        @current-change="getData"
        :current-page.sync="page.pageNo"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "member-history",
  data() {
    return {
      loading: false,
      tableData: [
        {
          a: "3个月",
          b: "银行卡转账",
          c: "2020-12-12 18:30:22",
          d: "续费成功",
          e: "2022-12-12",
        },
        {
          a: "3个月",
          b: "银行卡转账",
          c: "2020-12-12 18:30:22",
          d: "续费成功",
          e: "2022-12-12",
        },
        {
          a: "3个月",
          b: "银行卡转账",
          c: "2020-12-12 18:30:22",
          d: "续费成功",
          e: "2022-12-12",
        },
        {
          a: "3个月",
          b: "银行卡转账",
          c: "2020-12-12 18:30:22",
          d: "续费成功",
          e: "2022-12-12",
        },
        {
          a: "3个月",
          b: "银行卡转账",
          c: "2020-12-12 18:30:22",
          d: "续费成功",
          e: "2022-12-12",
        },
        {
          a: "3个月",
          b: "银行卡转账",
          c: "2020-12-12 18:30:22",
          d: "续费成功",
          e: "2022-12-12",
        },
        {
          a: "3个月",
          b: "银行卡转账",
          c: "2020-12-12 18:30:22",
          d: "续费成功",
          e: "2022-12-12",
        },
        {
          a: "3个月",
          b: "银行卡转账",
          c: "2020-12-12 18:30:22",
          d: "续费成功",
          e: "2022-12-12",
        },
        {
          a: "3个月",
          b: "银行卡转账",
          c: "2020-12-12 18:30:22",
          d: "续费成功",
          e: "2022-12-12",
        },
        {
          a: "3个月",
          b: "银行卡转账",
          c: "2020-12-12 18:30:22",
          d: "续费成功",
          e: "2022-12-12",
        },
        {
          a: "3个月",
          b: "银行卡转账",
          c: "2020-12-12 18:30:22",
          d: "续费成功",
          e: "2022-12-12",
        },
        {
          a: "3个月",
          b: "银行卡转账",
          c: "2020-12-12 18:30:22",
          d: "续费成功",
          e: "2022-12-12",
        },
      ],
      page: {
        pageNo: 1,
        pageNum: 1,
        pageSize: 8,
        rowCount: 8,
      },
    };
  },
  methods: {
    getData(pageno = 1) {},
  },
};
</script>

<style lang="scss" scoped>
#history {
  display: flex;
  flex-direction: column;
  margin: 0 0 114px;
  .history-info {
    height: 80px;
    border: 1px solid #ededed;
    font-size: 20px;
    color: #333;
    line-height: 78px;
    span {
      i {
        color: #3883ff;
        font-style: normal;
      }
      &:first-child {
        margin: 0 70px 0 24px;
      }
    }
  }
  .page {
    margin-top:20px;
    display: flex;
    justify-content: space-between;
    padding:0 20px;
    a {
      cursor: pointer;
      align-items: center;
    }
    a:hover{
      color:#3883ff;
    }
  }
}
</style>
